import React, { useState, useEffect } from 'react';
import { Menu, X } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import chrome from "../../assets/new/chrome.png";
import logo from "../../assets/new/logo.png"
import { useScrollToSection } from '../../hooks/useScrollToSection';

const Navbar = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [showWebStore, setShowWebStore] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const scrollToSection = useScrollToSection();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
      setShowWebStore(window.scrollY > window.innerHeight);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    const hash = location.hash.replace('#', '');
    if (hash) {
      setTimeout(() => {
        const element = document.getElementById(hash);
        if (element) {
          const navbarHeight = 80;
          const offsetPosition = element.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
          window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
        }
      }, 0);
    }
  }, [location]);

  const toggleDrawer = () => setIsDrawerOpen(!isDrawerOpen);

  const handleNavigation = (path, sectionId) => {
    setIsDrawerOpen(false);

    if (window.location.pathname === '/') {
      const element = document.getElementById(sectionId);
      if (element) {
        const navbarHeight = 80;
        const offsetPosition = element.getBoundingClientRect().top + window.pageYOffset - navbarHeight;
        window.scrollTo({ top: offsetPosition, behavior: 'smooth' });
      }
    } else {
      scrollToSection('/', sectionId);
    }
  };

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  const NavLink = ({ target, children }) => (
    <a
      href={target === 'blog' ? '/blog' : `#${target}`}
      className="text-[#6B7280] hover:text-gray-900 px-3 py-2 text-sm lg:text-base font-medium transition-colors whitespace-nowrap"
      onClick={(e) => {
        e.preventDefault();
        if (target === 'blog') {
          navigate('/blog');
          window.scrollTo(0, 0);
        } else {
          handleNavigation('/', target);
        }
      }}
    >
      {children}
    </a>
  );

  const ChromeButton = ({ className = '' }) => (
    <AnimatePresence>
      {showWebStore && (
        <motion.a
          href="https://chromewebstore.google.com/detail/locktheload-loads-booking/abfpabcljnjfonkbepfodpdembndjjnk"
          target="_blank"
          rel="noreferrer"
          className={`inline-flex items-center px-4 lg:px-6 py-2 lg:py-3 bg-[#4D8AF0] text-white text-sm lg:text-base rounded-lg hover:bg-blue-600 transition-colors whitespace-nowrap ${className} h-[44px]`}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <img src={chrome} alt="Chrome" className="w-5 h-5 lg:w-6 lg:h-6 mr-2" />
          Add to Chrome
        </motion.a>
      )}
    </AnimatePresence>
  );

  return (
    <>
      <div className="h-20"></div>

      <div className={`fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${isScrolled ? 'bg-white shadow-md' : 'bg-transparent'
        }`}>
        <nav className="container mx-auto px-4 lg:px-6 py-4">
          <div className="flex justify-between items-center">
            <div className="flex-shrink-0">
              <img
                src={logo}
                alt="LOCK the LOAD"
                className="h-8 w-auto cursor-pointer"
                onClick={handleLogoClick}
              />
            </div>

            <div className="hidden md:flex items-center justify-center flex-1 px-4 lg:px-8">
              <div className={`flex space-x-1 lg:space-x-2 rounded-full transition-all duration-300 ${isScrolled ? '' : 'bg-white/80 backdrop-blur-sm px-3 lg:px-4 py-2'
                }`}>
                <NavLink target="features">Features</NavLink>
                <NavLink target="pricing">Plan</NavLink>
                <NavLink target="reviews">Reviews</NavLink>
                <NavLink target="blog">Blog</NavLink>
                <NavLink target="faq">FAQ</NavLink>
              </div>
            </div>

            <div className="hidden md:block">
              <ChromeButton />
            </div>

            <button
              onClick={toggleDrawer}
              className="md:hidden text-[#6B7280] hover:text-gray-900 p-2"
            >
              <Menu size={24} />
            </button>
          </div>
        </nav>

        {/* Mobile drawer */}
        <AnimatePresence>
          {isDrawerOpen && (
            <>
              <motion.div
                className="fixed inset-0 bg-gray-600 bg-opacity-75 md:hidden"
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                onClick={toggleDrawer}
              />
              <motion.div
                className="fixed inset-y-0 right-0 max-w-xs w-full bg-white shadow-xl flex flex-col md:hidden"
                initial={{ x: "100%" }}
                animate={{ x: 0 }}
                exit={{ x: "100%" }}
                transition={{ type: "tween" }}
              >
                <div className="p-4 flex justify-between items-center border-b">
                  <img
                    src={logo}
                    alt="LOCK the LOAD"
                    className="h-8 w-auto cursor-pointer"
                    onClick={() => {
                      handleLogoClick();
                      setIsDrawerOpen(false);
                    }}
                  />
                  <button
                    onClick={toggleDrawer}
                    className="text-[#6B7280] hover:text-gray-900 p-2"
                  >
                    <X size={24} />
                  </button>
                </div>
                <div className="flex flex-col px-4 py-6 space-y-4">
                  {['features', 'pricing', 'reviews', 'blog', 'faq'].map((item) => (
                    <a
                      key={item}
                      href={item === 'blog' ? '/blog' : `#${item}`}
                      className="text-[#6B7280] hover:text-gray-900 px-4 py-2 text-base font-medium capitalize rounded-md hover:bg-gray-50 transition-colors"
                      onClick={(e) => {
                        e.preventDefault();
                        if (item === 'blog') {
                          navigate('/blog');
                          window.scrollTo(0, 0);
                          setIsDrawerOpen(false);
                        } else {
                          handleNavigation('/', item);
                        }
                      }}
                    >
                      {item}
                    </a>
                  ))}
                </div>
                <div className="mt-auto p-4 border-t">
                  <ChromeButton className="w-full justify-center" />
                </div>
              </motion.div>
            </>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default Navbar;