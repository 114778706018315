import { Box } from "@mui/system";
import Navbar from "./views/Navbar/Navbar.jsx";
import Footer from "./views/Footer/Footer.jsx";
import { Routers } from "./routes/Routers.jsx";
import { BrowserRouter } from "react-router-dom";
import "./global.css"
import { Helmet, HelmetProvider } from "react-helmet-async";

function App() {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <Helmet>
          <title>LockTheLoad - Loads Booking Manager</title>
          <meta name="description" content="Find better-paying loads, work with trusted brokers, and make smarter decisions easier with LockTheLoad's load booking manager." />
          <meta name="keywords" content="load booking, trucking, logistics, freight management, load board integration" />
          <meta property="og:title" content="LockTheLoad - Loads Booking Manager" />
          <meta property="og:description" content="Find better-paying loads, work with trusted brokers, and make smarter decisions easier." />
          <meta property="og:type" content="website" />
          <meta property="og:url" content="https://locktheload.com" />
          <link rel="canonical" href="https://locktheload.com" />
        </Helmet>
        <Box>
          <Navbar />
          <Routers />
          <Footer />
        </Box>
      </HelmetProvider>
    </BrowserRouter>
  );
}

export default App;
