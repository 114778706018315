import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import Loading from '../components/Loading';

const Home = React.lazy(() => import('../pages/Home').then(module => ({ default: module.Home })));
const Blog = React.lazy(() => import('../pages/Blog/Blog'));
const CalendlyScheduler = React.lazy(() => import('../pages/calendarly/CalendlyScheduler'));
const BlogItem = React.lazy(() => import('../pages/Blog/BlogItem'));
const PrivacyPolicy = React.lazy(() => import('../pages/Privacy/PrivacyPolicy').then(module => ({ default: module.PrivacyPolicy })));
const PromotionPage = React.lazy(() => import('../pages/PromotionPage/PromotionPage'));
const NotFound = React.lazy(() => import('../views/NotFound/NotFound'));

export const Routers = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path='/blog' element={<Blog />} />
        <Route path='/demo' element={<CalendlyScheduler />} />
        <Route path='/blog/:id' element={<BlogItem />} />
        <Route path='/privacy' element={<PrivacyPolicy />} />
        <Route path='/promotion' element={<PromotionPage />} />
        <Route path='/terms-conditions' element={<PrivacyPolicy />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};